import React from "react";
import MainLayout from "../Layouts/MainLayout";

function withLayout<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  LayoutComponent: React.ComponentType = MainLayout
) {
  return (props: P) => {
    return (
      <LayoutComponent>
        <WrappedComponent {...props} />
      </LayoutComponent>
    );
  };
}

export default withLayout;
