import React from "react";
import Link from "./common/Link";

import { LinkData } from "../models/LinkData";

interface NavLinksProps {
  linksCollection: LinkData[];
  activeLink: string;
  mobile?: boolean;
}

const NavLinks = ({
  linksCollection,
  activeLink,
  mobile = false,
}: NavLinksProps) => {
  const mobileNavLinks = (
    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
      {linksCollection.map(linkObj => {
        const { href, title } = linkObj;

        return (
          <Link
            to={href}
            key={`m-${title}`}
            className={`${
              activeLink === title
                ? "bg-gray-900"
                : "hover:text-white hover:bg-gray-700"
            } block px-3 py-2 rounded-md text-base font-medium text-white`}
          >
            {title}
          </Link>
        );
      })}
    </div>
  );

  const desktopNavLinks = (
    <div className="ml-10 flex items-baseline space-x-4">
      {linksCollection.map(linkObj => {
        const { href, title } = linkObj;

        return (
          <Link
            to={href}
            key={`d-${title}`}
            className={`${
              activeLink === title
                ? "text-white bg-gray-900"
                : "text-gray-300 hover:text-white hover:bg-gray-700"
            } px-3 py-2 rounded-md text-sm font-medium`}
          >
            {title}
          </Link>
        );
      })}
    </div>
  );

  return mobile ? mobileNavLinks : desktopNavLinks;
};

export default NavLinks;
