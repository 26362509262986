import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";

import Image from "./common/Image";
import { MediaType } from "../models/Media";
import { AudioData } from "../models/Audio";
import { PhotoData } from "../models/Photo";
import { VideoData } from "../models/Video";

interface SmartMediaProps {
  audioObj?: AudioData;
  photoObj?: PhotoData;
  videoObj?: VideoData;
  mediaType: MediaType;
}

const SmartMedia = ({
  photoObj,
  videoObj,
  audioObj,
  mediaType,
}: SmartMediaProps) => {
  switch (mediaType) {
    case MediaType.Image:
      return (
        <div className="w-full max-w-md mx-auto">
          <GatsbyImage image={photoObj.gatsbyImage} alt={photoObj.title} />
        </div>
      );
    case MediaType.Video:
      return (
        <ReactPlayer
          url={videoObj.media.src}
          className="w-full"
          width="100%"
          loop={true}
          playing={true}
        />
      );
    case MediaType.Audio:
      return (
        <ReactPlayer
          url={audioObj.media.src}
          className=""
          config={{ file: { forceAudio: true } }}
        />
      );
    default:
      return <Image />;
  }
};

export default SmartMedia;
