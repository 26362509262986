import React from "react";
import Link from "../components/common/Link";

import SmartMedia from "./SmartMedia";
import { MediaType } from "../models/Media";
import { PhotoData } from "../models/Photo";
import { VideoData } from "../models/Video";

interface HorizontalCardProps {
  title: string;
  description: string;
  href?: string;
  photoObj?: PhotoData;
  videoObj?: VideoData;
  mediaType: MediaType;
}

const HorizontalCard = ({
  title,
  description,
  href,
  photoObj,
  videoObj,
  mediaType,
}: HorizontalCardProps) => {
  return (
    <Link to={href} target="_blank">
      <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="flex-1 flex flex-col p-8">
          <SmartMedia
            photoObj={photoObj}
            videoObj={videoObj}
            mediaType={mediaType}
          />
          <h3 className="mt-6 text-gray-900 text-sm font-medium">{title}</h3>
          <dl className="mt-1 flex-grow flex flex-col justify-between">
            <dt className="sr-only">Description</dt>
            <dd className="text-gray-500 text-sm">{description}</dd>
          </dl>
        </div>
      </li>
    </Link>
  );
};

export default HorizontalCard;
