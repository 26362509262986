import React from "react";
import { useLocation, useParams } from "@reach/router";
import SEO from "../SEO";

import "tailwindcss/tailwind.css";
import NavigationModule from "../../modules/Navigation";
import FooterModule from "../../modules/Footer";

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const params = useParams();

  return (
    <div>
      <SEO />
      <NavigationModule currentPath={location.pathname} />
      {children}
      <FooterModule />
    </div>
  );
};

export default MainLayout;
