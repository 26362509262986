import React from "react";

interface PageHeaderProps {
  header: string;
  subheader?: string;
}

const PageHeader = ({ header, subheader }: PageHeaderProps) => {
  return (
    <div>
      <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
        {header}
      </h2>
      <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
        <p className="text-xl text-gray-500">{subheader}</p>
      </div>
    </div>
  );
};

export default PageHeader;
