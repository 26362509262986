import React from "react";
import { graphql } from "gatsby";

import withLayout from "../../components/hoc/withLayout";
import PageHeader from "../../components/PageHeader";

import SoftwareJSON from "../../../content/Software.json";
import HorizontalCard from "../../components/HorizontalCard";

import {
  btoaEncode,
  remapGatsbyImages,
  findPhotoByName,
} from "../../lib/utils";

interface SoftwareProps {
  data: {
    allFile: {
      edges: any[];
    };
  };
}

const Software = ({
  data: {
    allFile: { edges: photos },
  },
}: SoftwareProps) => {
  const photoObjs = photos.map(edge => {
    return edge.node;
  });

  const remappedPhotoObjs = remapGatsbyImages(photoObjs);

  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <PageHeader
          header="Software"
          subheader="Dabbling a little here. Dabbling a little there."
        />
        <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
          {SoftwareJSON.software.map((software: any) => {
            const { href, title, description, media, mediaType } = software;

            // need to match up filename
            if (mediaType === 0) {
              const gatsbyImageData = findPhotoByName(
                media.filename,
                remappedPhotoObjs
              );
              software.gatsbyImage = gatsbyImageData;;
            }

            // convert the asset title to base64 for id
            const cardId = btoaEncode(title);

            return (
              <HorizontalCard
                key={cardId}
                href={href}
                mediaType={mediaType}
                title={title}
                description={description}
                photoObj={mediaType === 0 ? software : {}}
                videoObj={mediaType === 1 ? software : {}}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default withLayout(Software);

export const softwarePageQuery = graphql`
  query SOFTWARE_PAGE_QUERY {
    allFile(
      filter: {
        extension: { regex: "/(png)/" }
        relativeDirectory: { eq: "images/software" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          childrenImageSharp {
            fluid {
              originalName
            }
          }
        }
      }
    }
  }
`;
